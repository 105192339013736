$(function(){
    var headerHight = $('.p-header').outerHeight();
    $('[data-moveTo]').on('click', function (){
        var speed = 500;
        var href= $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - headerHight;
        $('html, body').animate({scrollTop:position}, speed, 'easeInQuad');
        return false;
    });
});
